@import '../../scss/values';
@import '../../scss/mixins/fonts';

// TODO: rename, use neutral class names
// use as common styles for all scanner-filters
// do not place here overrides for specific scanner-filters, only for common styles
.wheel-filter {
  background: var(--info-bg-color);
  border-bottom: 1px solid var(--wheel-scanner-filter-border-color);
  padding: 15px 15px 10px 15px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  transition: max-height 0.3s ease;

  .invalid-input {
    border-color: var(--wheel-filter-input-invalid-value-color);
    color: var(--wheel-filter-input-invalid-value-color);
  }

  .title-block {
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-bottom: 10px;

    .component-btn.close-open-item-btn {
      margin-right: 8px;
      width: 21px;
      height: 21px;
    }

    .close-open-arrow {
      border: 1px solid var(--close-open-btn-font);
      border-radius: 50%;
    }

    .arrow-wrapper {
      width: 21px;
      height: 21px;
      display: block;
      background-color: transparent;

      .close-open-arrow {
        width: 21px;
        height: 21px;
        display: block;
        transition: 0.2s;

        &::after {
          top: 10px;
          left: 5px;
          transform: rotate(45deg);
          background-color: var(--close-open-btn-font);
        }

        &::before {
          top: 10px;
          left: 9px;
          transform: rotate(-45deg);

          background-color: var(--close-open-btn-font);
        }
      }

      &.close-item {
        .close-open-arrow {
          &::after {
            top: 9px;
            transform: rotate(-45deg);
            transition: 0.3s;
          }

          &::before {
            top: 9px;
            transform: rotate(45deg);
            transition: 0.3s;
          }
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: pointer;
      outline: none;
      color: var(--font-dark-color);
      position: relative;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        outline: none !important;
      }

      img {
        margin-right: 5px;
      }

      .filters-count {
        background: var(--wheel-scanner-filter-changes-indicator-color);
        border-radius: 50%;
        font-weight: bold;
        font-size: 10px;
        width: 6px;
        height: 6px;
        text-align: center;
        position: absolute;
        bottom: 3px;
        left: 8px;
      }
    }

    .actions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: auto;
      height: 32px;

      .refresh {
        position: relative;
        margin: 0;
        padding: 9px 15px;
        border-radius: 4px;
        color: var(--btn-primary-color);
        background: var(--light-bg-17);
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        height: 32px;

        svg {
          vertical-align: top;
          margin-right: 4px;
        }

        &:hover {
          background: var(--light-hover-color-5);
        }
      }

      .recommended-btn {
        top: -4px;
        height: 32px;
        margin: 0 10px;
        padding: 9px 15px !important;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 14px;
        border-radius: 4px;
      }
    }
  }

  &.hide {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow: hidden;
    padding-bottom: 10px;

    .filters {
      height: 0;
      visibility: hidden;
    }

    .filter-input-data-duplicate {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1px 8px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      overflow-x: hidden;
      padding-top: 5px;
      cursor: pointer;

      li {
        list-style-type: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: var(--main-font-color);

        &:after {
          content: ';';
        }

        &:last-child:after {
          content: '.';
        }

        .duplicate-data {
          font-weight: 600;
        }
      }
    }
  }

  .filter-input-data-duplicate {
    display: none;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .filters-groups-separator {
      height: 1px;
      width: 100%;
      border-top: 1px dashed var(--form-separator-border-color);
    }

    .filters-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      align-items: center;
      gap: 4px 2px;
      padding-top: 0;
      margin: 0 -6px;

      .empty-input-item {
        flex-grow: 1;
        flex-basis: 47%;
        min-width: 160px;
      }

      .input-item {
        flex-grow: 1;
        flex-basis: 47%;
        min-width: 160px;
        max-width: 100%;
        min-height: 24px;
        margin: 0 !important;
        padding: 2px 2px 0 6px;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: var(--wheel-scanner-filter-input-item-hover-color);
          border-radius: 3px;

          .form-group {
            .top-label-result {
              .mat-icon {
                display: block;
              }
            }
          }
        }

        .mat-mdc-form-field.scanner-filter-field .mat-mdc-text-field-wrapper {
          padding-inline: 0;
          &:hover {
            background-color: transparent;
          }
        }

        .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
          opacity: 0;
        }

        .mat-mdc-form-field-focus-overlay {
          background-color: transparent;
        }

        .fields-wrapper {
          display: flex;
          align-items: center;

          mat-mdc-form-field {
            flex-grow: 1;
          }

          .wheel-input {
            padding: 5px 4px;

            @media screen and (min-width: $mobile-min-425px-width-trigger) {
              padding: 5px 7px;
            }
          }

          .fields-separator {
            display: block;
            width: 10px;
            height: 1px;
            background-color: var(--close-open-btn-font);
            margin: 0 3px;
          }
        }

        .form-group {
          &.flags {
            .top-label {
              width: auto;
            }
          }

          .top-label {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            max-width: 100%;
            width: 100%;
            margin-bottom: 0;
            white-space: nowrap;

            .hint-icon {
              flex: 0 0 15px;
            }
          }

          .underline {
            border-bottom: 1px dashed var(--btn-primary-color);
          }

          .top-label-result {
            padding-right: 4px;
            min-height: 16px;
            display: inline-flex;
            font-weight: bold;
            color: var(--main-font-color);
            position: relative;
            max-width: 100%;
            width: 100%;
            overflow: hidden;

            .flags-result {
              font-weight: bold;
              color: var(--main-font-color);
              align-items: flex-end;
            }

            .summary {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 2px;
            }

            .mat-icon {
              display: none;
              position: absolute;
              top: 40%;
              right: 0;
              transform: translateY(-50%);
              color: var(--close-open-btn-font);
            }
          }
        }
      }
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
}

.wheel-filter .filters {
  .mat-focused {
    .wheel-input,
    .item-select {
      border-color: var(--primary-color);
    }
  }

  .mat-mdc-form-field-flex {
    border: none;
  }

  .item-select {
    padding: 0 !important;
    width: 0;
    height: 22px !important;
    overflow: hidden;

    .mat-mdc-select-trigger {
      height: 22px !important;

      .flag-select-trigger {
        display: grid;
        grid-template-columns: repeat(auto-fit, 10px);
        grid-auto-flow: column;

        & > span {
          height: 16px;
          width: 16px;
          background-color: var(--white-bg);
          border-radius: 50%;

          &.none {
            background-color: #fff;
            border-radius: 50%;
          }

          img {
            max-width: 16px;
            height: auto;
          }
        }
      }
    }

    .mat-mdc-select-value-text {
      height: 22px;
      padding: 0;
      width: 0;
    }

    &.filters-select.flag-select {
      margin-left: 4px;
      width: var(--filters-select-width);
      max-width: 100%;
      flex-direction: row;
      overflow: visible;

      .mat-mdc-select-value {
        overflow: visible;
      }

      .mat-mdc-select-value-text {
        height: 22px;
        padding: 0;
        width: calc(4px + var(--filters-select-width));
      }
    }
  }
}

@media screen and (min-width: $mobile-min-500px-width-trigger) {
  .wheel-filter {
    .filters {
      .filters-group {
        .input-item {
          flex-grow: 1;
          flex-basis: 30%;
          min-width: 130px;
        }

        .input-item:first-child {
          flex-basis: 25%;
          min-width: 110px;
        }

        .empty-input-item {
          flex-grow: 1;
          flex-basis: 30%;
          min-width: 130px;
        }
      }
    }
  }
}

.wheel-scanner-mat-menu-heading {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    @include apply-wheel-scanner-filter-modal-title-font();
    color: var(--font-dark-color);
  }
}

// TODO: move it from here into short-selling-stocks-scanner-watchlist-panel scss file, it's not even for scanner-filters
.short-selling-stocks-scanner-watchlist-panel .short-selling-stocks-scanner-symbols-list {
  .mat-mdc-table {
    .mat-column-symbol.symbol {
      @media screen and (max-width: 470px) {
        width: 66px;
      }
    }

    .mat-column-company {
      padding-left: 0 !important;
      max-width: 250px;
      width: 28%;

      @media screen and (max-width: $tablet-991px-width-trigger) {
        width: 35%;
      }

      @media screen and (max-width: $mobile-min-500px-width-trigger) {
        width: 66px;
      }

      .company-cell-content.with-flags-statistic {
        padding-left: 0;
      }
    }
  }
}
