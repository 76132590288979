.mat-icon {
  flex-shrink: 0;

  &.extra-small-icon {
    @include apply-icon-size($extra-small-svg-icon-size);
  }

  &.small-icon {
    @include apply-icon-size($small-svg-icon-size);
  }

  &.middle-icon {
    @include apply-icon-size($middle-svg-icon-size);
  }

  &.removed-btn-icon {
    @include apply-icon-size($small-svg-icon-14px-size);
  }

  &.big-icon {
    @include apply-icon-size($big-svg-icon-size);
  }

  &.extra-low-icon {
    @include apply-icon-size($extra-small-svg-icon);
  }

  &.extra-small-icon {
    @include apply-icon-size($extra-small-svg-icon-size);
  }

  &.l-icon {
    @include apply-icon-size($small-svg-icon-16px-size);
  }

  &.icon-s-size {
    @include apply-icon-size($modal-close-icon-size);
  }

  &.xl-icon {
    @include apply-icon-size($small-svg-icon-18px-size);
  }

  &.custom-icon-size {
    width: 100%;
    height: 100%;
    font-size: inherit;
    line-height: unset;
  }

  &.chip-close {
    & > svg > path {
      fill: var(--mat-chips-close-button-color);
    }
  }

  &.reset {
    & > svg > path {
      fill: var(--chips-reset-button-icon-fill-color);
    }
  }

  &.drop-down-list-icon {
    & > svg > path {
      stroke: var(--main-font-color);
    }
  }

  &.tabs-mode-icon {
    & > svg > path,
    & > svg > rect {
      fill: var(--btn-news-icon-color);
    }
  }

  &.split-mode-icon {
    & > svg > .apply-fill {
      fill: var(--btn-news-icon-color);
    }

    & > svg > .apply-stroke {
      stroke: var(--btn-news-icon-color);
    }
  }

  &.watch-icon {
    @include apply-icon-size($small-svg-icon-16px-size);

    & > svg > path {
      fill: var(--calendar-icon-color);
    }
  }

  &.svg-menu-icon {
    & > svg > path {
      fill: var(--mat-icon-menu-svg-fill-color);
    }
  }

  &.svg-dashes-icon {
    & > svg > rect {
      fill: var(--mat-icon-dashes-menu-svg-fill-color);
    }
  }

  &.removed-btn-icon {
    border-radius: 50%;
    position: relative;
    margin-left: 5px;
    border: 1px solid var(--custom-removed-btn-icon-border-color);

    svg {
      position: absolute;
      left: 0;

      path {
        fill: var(--custom-removed-btn-font-color);
      }
    }

    &.selected-day {
      & > svg > path {
        fill: var(--economic-calendar-selected-day-watch-icon-color);
      }
    }
  }

  &.danger-label-btn-icon,
  &.danger-lable-btn-icon {
    border: 1px solid var(--danger-alpha);
    border-radius: 50%;
    position: relative;
    margin-left: 5px;

    svg {
      position: absolute;
      left: 0;

      path {
        fill: var(--danger-color);
      }
    }
  }

  &.print-icon {
    & > svg > path,
    rect {
      stroke: var(--primary-lg-color);
    }
  }

  &.attantion-sign {
    margin-right: 5px;
    top: 0;

    * {
      stroke: var(--table-cell-placeholder);
    }
  }

  &.warning-icon {
    & > svg > path,
    circle {
      stroke: var(--settings-warning-icon-color);
    }
  }

  &.failed-icon {
    & > svg > path,
    circle {
      stroke: var(--settings-error-text-color);
    }
  }

  &.svg-menu-icon {
    & > svg > path {
      fill: var(--mat-icon-menu-svg-fill-color);
    }
  }

  &.news-icon,
  &.star-icon {
    position: relative;
  }

  &.eye-icon:hover,
  &.eye-icon.visibility {
    cursor: pointer;

    svg {
      path {
        fill: var(--mat-select-label-counter);
      }
    }
  }

  &.eye-icon {
    & > svg > path {
      fill: var(--eye-icon-default-color);
    }

    &.cost-basis {
      & > svg > path {
        fill: var(--eye-icon-cost-basis-color);
      }

      &:hover,
      &.visibility {
        & > svg > path {
          fill: var(--eye-icon-cost-basis-color);
        }
      }
    }

    &.break-even {
      & > svg > path {
        fill: var(--eye-icon-break-even-color);
      }

      &:hover,
      &.visibility {
        & > svg > path {
          fill: var(--eye-icon-break-even-color);
        }
      }
    }
  }

  &.close-eye {
    & > svg > path {
      fill: var(--eye-icon-default-color);
    }

    &.cost-basis {
      &:hover,
      &.visibility {
        & > svg > path {
          fill: var(--eye-icon-cost-basis-color);
        }
      }
    }

    &.break-even {
      &:hover,
      &.visibility {
        & > svg > path {
          fill: var(--eye-icon-break-even-color);
        }
      }
    }
  }

  &.news-icon {
    color: var(--btn-news-icon-color);

    path {
      fill: var(--btn-news-icon-color);
    }
  }

  &.analyze-earning-icon {
    color: var(--btn-news-icon-color);

    path {
      fill: var(--btn-news-icon-color);

      &:nth-child(2) {
        stroke: var(--btn-news-icon-color);
      }
    }

    rect {
      fill: var(--btn-news-icon-color);
    }
  }

  &.star-icon {
    path {
      fill: transparent;
      stroke: var(--btn-star-icon-border-color);
    }

    &.add-to-list-icon {
      path {
        fill: var(--btn-star-icon-color);
        stroke: var(--btn-star-icon-color);
      }
    }
  }

  &.chip-icon {
    margin-right: 7px;
  }

  &.monochrome-color-icon {
    color: var(--monochrome-icon-color);

    path {
      fill: var(--monochrome-icon-color);
    }
  }

  &.monochrome-color-icon-hover:hover {
    color: var(--monochrome-color-hover-color);

    path {
      fill: var(--monochrome-color-hover-color);
    }
  }

  &.config-icon,
  &.logout-icon {
    & > svg > g > g > g {
      stroke: var(--header-menu-item-icon-color);
    }
  }

  &.settings-icon {
    & > svg > g > g > g > g {
      stroke: var(--header-menu-item-icon-color);
    }
  }

  &.header-menu-icon {
    cursor: pointer;

    & > svg > path {
      stroke: var(--header-menu-icon-color);
    }

    &:hover {
      & > svg > path {
        stroke: var(--menu-icon-hover-color);
      }
    }
  }

  &.primary {
    path,
    svg {
      fill: var(--primary-color);
    }
  }

  &.danger {
    path,
    svg {
      fill: var(--danger-color);
    }
  }

  &.warn {
    path,
    svg {
      fill: var(--warn-color);
    }
  }

  &.success {
    path,
    svg {
      fill: var(--success-color);
    }
  }

  &.error-color {
    & > svg > path,
    circle {
      stroke: var(--red-color);
    }
  }

  &.time {
    svg circle,
    svg path {
      stroke: var(--main-font-color);
    }
  }

  &.primary-time-icon {
    svg circle,
    svg path {
      stroke: var(--active-link-color);
    }
  }

  &.label-icon.success {
    svg path {
      stroke: var(--success-static-color);
    }
  }

  &.delete-icon {
    svg path {
      fill: var(--current-icon-color);
    }
  }

  &.time-icon {
    svg circle,
    svg path {
      stroke: var(--header-menu-icon-color);
    }
  }

  &.edit-icon {
    svg path {
      fill: var(--edit-icon-color);
    }
  }

  &.arrow {
    path {
      fill: var(--light-success-static-color);
    }

    &.down path {
      fill: var(--light-danger-static-color);
    }
  }

  &.person-impersonate {
    svg path {
      stroke: var(--impersonate-panel-person-icon-color);
    }
  }

  &.close-modal-icon {
    color: var(--modal-close-icon-color);
  }

  &.tl-quotes-icon {
    color: var(--trading-log-quoutes-icon-color);
  }
}

button:disabled .mat-icon {
  opacity: 0.7;

  &.reset-input-value {
    & > svg > g > g > g > g {
      stroke: var(--monochrome-color-hover-color);
    }
  }

  &.arrow {
    path {
      fill: var(--light-success-static-color);
    }

    &.down path {
      fill: var(--light-danger-static-color);
    }
  }

  &.down path {
    fill: var(--light-danger-static-color);
  }

  &.dark-close-icon {
    & > svg > g > g > g > g {
      stroke: var(--main-font-color);
    }

    &.arrow-icon {
      svg path {
        stroke: var(--custom-action-btn-font-color);
      }
    }
  }
}

.icon-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--icon-bg-color, #d3d3e1);
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--icon-accent-color, #787b86);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon-square {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--icon-bg-color, #d3d3e1);
  position: relative;

  &.rhombus {
    transform: rotate(45deg);
    margin-right: 1px;
    margin-left: 1px;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: var(--icon-accent-color, #787b86);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.weekly-options-icon {
  @include weekly-options-icon-style();
  text-transform: uppercase;
  margin-right: 4px;
}
