@import '../values';
@import '../mixins/fonts';
@import '../mixins/images';
@import '../mixins/line-clamp';

:host {
  display: block;
  max-height: calc(100dvh - var(--new-header-height));
  overflow-y: auto;
}

.combined-calendars-page-content {
  padding: 12px 20px;
  overflow-x: auto;
}

/*
  FILTERS CONTAINER
*/
.calendar-filters-wrapper {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 8px;
  gap: 4px 24px;
  z-index: 1;
  background-color: var(--white-bg);
  border-bottom: 1px solid var(--divider-color);

  @media screen and (max-width: $mobile-max-width-trigger) {
    top: unset;
    gap: 8px;
  }

  .calendar-filter {
    min-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px 0;

    &.confirmed-only-filter {
      @media screen and (max-width: $mobile-max-width-trigger) {
        order: 1;
      }
    }

    &.week-selector-legend-filter {
      @media screen and (max-width: $mobile-max-width-trigger) {
        order: 2;
      }
    }

    .calendar-filter-title {
      flex: 1 1 100%;
      @include title-description-font();
      color: var(--main-font-color);
      text-transform: uppercase;
    }

    .calendar-filter-content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0 16px;
    }

    &.vertical-align-center {
      justify-content: center;
    }

    &.vertical-align-bottom {
      justify-content: flex-end;
    }

    &.position-right {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .calendar-filter {
    .calendar-status {
      .calendar-status-item {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        .mat-icon-button.btn-standard {
          width: 24px;
          height: 24px;
        }

        .mat-icon {
          color: var(--primary-color);
          @include apply-icon-size(18px);
        }

        .mode-title {
          @include font-regular(10px, 120%, 0.1px, var(--header-font-colors));
          text-transform: uppercase;
        }

        &:hover {
          .btn-standard {
            background-color: var(--chip-container-field-clear-button-hover-bg-color);
          }
        }
      }
    }
  }

  .weeks-selector-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
    overflow: hidden;

    .select-wrap {
      .selected-week-container, // overrides for weeks-selector component
      .highlight-selector {
        &.previous-week {
          background: var(--master-calendar-previous-week-selector-bg);

          .week-name {
            color: var(--master-calendar-previous-week-selector-font-color);
          }
        }

        &.current-week {
          background: var(--master-calendar-current-week-selector-bg);

          .week-name {
            color: var(--master-calendar-current-week-selector-font-color);
          }
        }

        &.next-week {
          background: var(--master-calendar-next-week-selector-bg);

          .week-name {
            color: var(--master-calendar-next-week-selector-font-color);
          }
        }
      }
    }

    // override default weeks-selector component styles
    .weeks-selector {
      min-width: 380px;
      height: 31px;
      border-radius: 4px;
      border: 1px solid var(--table-border-color);
      display: flex;

      @media screen and (max-width: $mobile-min-425px-width-trigger) {
        min-width: auto;
        max-width: 100%;
      }

      .select-wrap {
        flex-grow: 1;
        border-right: 1px solid var(--table-border-color);
        border-left: 1px solid var(--table-border-color);
        overflow: hidden;

        @media screen and (max-width: $mobile-min-425px-width-trigger) {
          max-width: calc(100% - 62px);
        }

        .form-group {
          margin: 0;
          height: 29px;

          .mat-select {
            border: none;
          }
        }
      }

      .icon-button.week-control-btn {
        --mdc-icon-button-state-layer-size: 30px;
        --mdc-icon-button-icon-size: 16px;
        border-radius: 0;
        background-color: transparent;

        &:first-child {
          .mat-icon {
            transform: rotate(90deg);
          }
        }

        &:last-child {
          .mat-icon {
            transform: rotate(-90deg);
          }
        }
      }
    }

    @media screen and (max-width: $mobile-min-425px-width-trigger) {
      width: 100%;

      .master-calendar-weeks-selector {
        width: 100%;
      }

      .weeks-selector {
        width: 100% !important;
        min-width: 360px;
      }
    }

    .custom-events-time {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 2px 6px;
      color: var(--main-font-color);
      margin-left: 2px;
      flex-grow: 1;

      .time-title {
        @include title-description-font();
        text-transform: uppercase;
        color: var(--description-color);
      }

      .current-time-wrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        .current-time {
          @include title-description-font();
          text-transform: none;
          color: var(--time-and-date-font-color);
        }
      }
    }
  }

  .legend-wrapper {
    margin-block: 6px 4px;
    display: flex;
    align-items: center;
    gap: 2px;

    &:hover {
      cursor: pointer;
    }

    .legend-item {
      padding-inline: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--spacings-2, 2px);

      &.high {
        background: var(--red-point);
      }

      &.med {
        background: var(--orange-point);
      }

      &.low {
        background: var(--yellow-point);
      }

      &.non {
        background: var(--grey-point);
      }

      .legend-item-text {
        padding-top: 2px;
        @include font-bold(10px, 16px, normal, var(--white-color));
        text-transform: uppercase;
      }
    }

    .mat-icon {
      margin-left: 4px;
      @include apply-icon-size(16px);
      color: var(--primary-color);

      &.opened {
        rotate: 180deg;
      }
    }
  }

  .view-control-wrapper {
    padding: 4px;
    display: flex;
    align-items: center;

    .toggle-description {
      :is(.header, .title) {
        @include font-regular(14px, normal, normal, var(--main-headers-font-color));
        margin: 0;
      }
    }
  }

  .view-switcher {
    margin: 0;
    padding: 3px;
    height: 32px;

    .view-switcher-btn {
      height: 100%;

      .country-flag-icon {
        margin-right: 2px;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-size: contain;
      }

      .view-switcher-text {
        line-height: 24px;
      }
    }
  }

  .week-selector-legend-filter {
    margin-left: auto;
  }

  .week-selector-legend {
    @include font-regular(12px, 16px, -0.01em);
    white-space: nowrap;

    &::before {
      margin-right: 5px;
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--earnings-calendar-progressbar-percentage-bg-color);
    }
  }

  .mat-mdc-icon-button {
    .mat-icon {
      @include apply-icon-size(20px);
      color: var(--primary-color);
    }
  }
}

/*WEEK SELECTOR CUSTOM PANEL STYLES for MASTER CALENDAR*/
.week-selector {
  .title-wrapper {
    &.extended {
      .title-section {
        @include font-regular(12px, 18px, 0.1px, var(--main-font-color));
        text-align: left;

        .events-by-week-count {
          margin-left: 8px;
          color: var(--primary-color);
        }

        &.week-details {
          text-align: left;

          .week-details-count {
            display: inline-block;
            width: 25px;
            flex: 0 0 25px;
            text-align: right;
          }

          .mat-icon {
            margin-right: 0;
            @include apply-icon-size(18px);
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

/*
  DAYS CONTAINER (BASE STYLES)
*/
.combined-calendars-page-content {
  .days-container {
    padding-block: 8px 30px;
    display: flex;
    align-items: stretch;
    gap: 16px;

    .day-outer-block {
      padding: 6px 0;
      scroll-margin-top: 68px;

      @media screen and (max-width: $mobile-max-width-trigger) {
        width: calc(100dvw - 32px);
        overflow: hidden;
        scroll-snap-align: center;
        scroll-margin-top: unset;
      }

      &.active {
        padding-inline: 16px;
        background: var(--earnings-calendar-current-day-bg-color);

        .day-inner-block:after {
          content: 'TODAY';
          position: absolute;
          top: 4px;
          right: 4px;
          font-family: 'Trebuchet MS', roboto, ubuntu, sans-serif;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: var(--primary-color);
        }
      }
    }
  }

  .day-inner-block {
    margin-bottom: 12px;
    padding: 12px 6px 10px;
    min-height: 48px;
    background: var(--day-block-bg-color);
    border: 1px solid var(--divider-color);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    position: relative;

    .day-inner-block-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .day-block-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: var(--main-font-color);
      }

      .day-block-date {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: var(--sub-header);
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }

  .day-inner-block-column {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .day-inner-block-column-heading {
      padding-inline: 32px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    .day-block-earnings-type-title {
      flex: 1 1 30%;
      min-width: 70px;
      @include font-regular(12px, 16px, 0.04em, var(--sub-header));
      text-align: center;
      text-transform: uppercase;
    }
  }

  .day-inner-block-column-list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .economic-calendar-card {
      border: 1px solid var(--divider-color);
      border-radius: 5px;
      overflow: hidden;
      display: flex;
    }

    .card-time-block {
      padding: 8px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      @include font-regular(12px, 16px);
      white-space: nowrap;
      background-color: var(--time-block-bg-color);
      writing-mode: vertical-lr;
      rotate: 180deg;

      .card-time-icon {
        @include apply-icon-size(16px);
        color: var(--monochrome-color-hover-color);
      }
    }

    .card-list {
      flex: 1;
      border-left: 1px solid var(--divider-color);
      background-color: var(--white-bg);

      .card-list-item {
        padding: 15px 8px;

        &.yt-link-container {
          margin: 0;
        }
      }

      .card-title {
        max-width: 240px;
        @include font-regular(12px, 16px, 0.04em, var(--main-font-color));
        @include line-clamp(2);
      }

      .card-earnings-list-item {
        position: relative;

        &.high {
          --earnings-item-color-indicator: var(--red-point);
        }

        &.med {
          --earnings-item-color-indicator: var(--orange-point);
        }

        &.low {
          --earnings-item-color-indicator: var(--yellow-point);
        }

        &.non {
          --earnings-item-color-indicator: var(--grey-point);
        }

        .card-earnings-list-item-title {
          margin-bottom: 8px;
          padding-left: 14px;
          position: relative;

          &:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 4px;
            background-color: var(--earnings-item-color-indicator);
            position: absolute;
            top: 50%;
            left: 0;
            translate: 0 -50%;
          }
        }

        .calendar-card-link-to-rocky {
          position: absolute;
          top: 10px;
          right: 8px;

          .ask-rocky-chat-button {
            filter: drop-shadow(-10px 0 5px var(--white-bg));
            cursor: pointer;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s ease;
          }

          @media screen and (max-width: $tablet-992px-width-trigger) {
            display: none; // hide on mobile
            opacity: 1;
            pointer-events: all;
          }

          .mat-icon {
            @include apply-icon-size(20px);
          }
        }

        .card-earnings-list-item-values {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 6px;

          .card-earnings-list-item-value {
            flex: 1 1 30%;
            min-width: 70px;
            @include font-regular(12px, 16px, 0.04em, var(--main-font-color));
            text-align: center;
            @include line-clamp(1);

            &.actual-alarm {
              color: var(--red-point);
            }

            &.actual-profit {
              color: var(--green-font);
            }
          }
        }

        &:hover {
          .ask-rocky-chat-button {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      .card-list-item + .card-list-item {
        border-top: 1px solid var(--divider-color);
      }
    }
  }

  .day-inner-block-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: $mobile-min-425px-width-trigger) {
      gap: 2px;
    }

    .market-data {
      flex: 0 1 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      .market-data-type {
        @include apply-earnings-calendar-market-data-type-font();
        color: var(--sub-header);
        position: relative;

        @media screen and (max-width: $mobile-min-425px-width-trigger) {
          font-size: 10px;
        }

        &::after {
          display: inline-block;
          content: '';
          width: 4px;
          height: 4px;
          background-color: var(--primary-color);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: -10px;
          transform: translateY(-50%);
        }
      }

      .market-data-stocks {
        @include apply-earnings-calendar-market-data-stocks-font();
        text-transform: uppercase;
        color: var(--font-dark-color);
        white-space: nowrap;
      }
    }

    .market-data-divider {
      display: none;
      min-height: 16px;
    }

    .market-data:not(:last-of-type) + .market-data-divider {
      display: block;
    }
  }

  .day-inner-block-earnings,
  .day-inner-block-dividends {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;

    @media screen and (max-width: $mobile-max-width-trigger) {
      justify-content: center;
    }

    .earnings-column {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .dividends-column {
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 284px;
    }

    .earning-event-placeholder {
      width: 140px;
      height: 91px;
    }

    .dividend-event-placeholder {
      width: 140px;
      height: 115px;
    }

    .day-earning-item,
    .day-dividend-item {
      width: 140px;
      padding: 10px;
      background-color: var(--white-bg);
      border: 1px solid var(--divider-color);
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background-color: var(--earnings-calendar-day-earning-block-bg-color);
      }

      .logo-container {
        width: 100%;
        height: 34px;
        margin-bottom: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
          display: block;
          margin-inline: auto;
          max-width: 100%;
          height: 34px;
        }
      }

      .logo-placeholder {
        display: block;
        margin-inline: auto;
        margin-bottom: 10px;
        width: 100%;
        max-width: 113px;
        height: 34px;
        background-image: var(--company-logo-placeholder);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid var(--earnings-calendar-day-earning-block-company-placeholder-logo-border-color);
        border-radius: 2px;
      }

      .logo-container,
      .logo-placeholder {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 777;
          background-color: transparent;
        }
      }

      .day-earning-item-heading,
      .day-dividend-item-heading {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
      }

      .title {
        @include apply-earnings-calendar-day-earning-title-font();
        max-width: 15ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: var(--font-dark-color);
      }

      .day-dividend-item-details {
        padding-top: 4px;
        text-align: center;
        color: var(--font-dark-color);
        font-size: 12px;
      }

      .mat-icon {
        flex-shrink: 0;
        opacity: 0;
      }

      &:hover .mat-icon {
        opacity: 1;
      }

      .progress {
        height: 5px;
        background: var(--earnings-calendar-progressbar-bg-color);
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        &::before {
          display: inline-block;
          content: '';
          position: absolute;
          inset: 0;
          width: var(--progress-bar-width, 0);
          background-color: var(--earnings-calendar-progressbar-percentage-bg-color);
        }
      }
    }
  }

  .day-inner-block-earnings {
    @media screen and (max-width: $mobile-min-450px-width-trigger) {
      .earnings-column {
        flex: 1 1 30%;
        width: 30%;
        max-width: min(140px, 50%);
      }

      .day-earning-item {
        width: 100%;

        .logo-container {
          .logo {
            object-fit: contain;
          }
        }
      }
    }
  }
}

/*
  HOLIDAYS CALENDAR DAYS CONTAINER (COMPONENT-SPECIFIC STYLES)
*/
.master-calendar .days-container.holidays-calendar-days-container {
  padding-block: 0;
}

.days-container.holidays-calendar-days-container {
  .day-outer-block {
    &.active {
      .day-inner-block:after {
        content: 'CURRENT';
      }
    }
  }

  .day-inner-block {
    min-width: 340px;
  }

  .day-inner-block-column {
    flex: 1;
  }

  .card-holidays-list-item {
    display: flex;
    align-items: stretch;

    &.archived > * {
      filter: saturate(0);

      .holiday-name {
        color: var(--sub-header);
      }
    }

    .card-holidays-list-item-day {
      margin-right: 8px;
      padding-right: 6px;
      flex: 0 0 60px;
      border-right: 1px solid var(--divider-color);

      .holiday-countries-flags {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        .flag-icon {
          @include apply-icon-size(16px);
        }
      }

      .week-day-name {
        margin-top: 4px;
        @include font-bold(12px, 16px, 0.04em, var(--sub-header));
      }
    }

    .card-holidays-list-item-title {
      align-self: center;
      @include font-regular(16px, 20px, 0.04em, var(--main-font-color));
    }
  }
}

/*
  YOUTUBE LINKS FOR ECONOMIC EVENTS
*/
.yt-input-wrapper {
  display: block;
  min-width: 280px;
  margin-bottom: 10px;

  .yt-input-container {
    padding-right: 0;
  }
}

.yt-link-container {
  display: block;
  min-width: 280px;
  margin-top: 10px;

  .yt-link {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 36px;
    border: 1px solid var(--secondary-btn-border);
    background: var(--yt-link-container-border-color);
    cursor: pointer;

    &:hover {
      background: var(--secondary-btn-bg-hover);
    }
  }

  .yt-link-title {
    @include font-regular(12px, 24px, normal, var(--main-font-color));
  }
}
