@import '../mixins/mobile-devices';
@import '../mixins/fonts';
@import '../mixins/images';
@import '../components/scroll';

/*
  This file should contain all global material modals (dialogs) styles fixes.
  Please remember: if you need to setup one special modal styles, try to do it in
  component's style first and update this file only if there is no other option.
*/

.mat-mdc-dialog-container .mdc-dialog__surface,
.mat-mdc-dialog-content,
.mat-mdc-tab-body-content {
  --mdc-dialog-container-color: var(--modal-bg);
  @extend .scroll-style;
}

.position-compare.modals .mat-mdc-dialog-container {
  margin-inline: auto;
  width: 1230px;

  // TODO: it's necessary for back compatibility - remove when styles.scss cleared / removed;
  @media (max-width: $tablet-max-width-trigger) {
    width: 85vw;
  }

  // TODO: it's necessary for back compatibility - remove when styles.scss cleared / removed;
  @media (max-width: 991px) {
    width: calc(100% - 50px);
  }
}

@media (max-width: 575px) {
  .symbol-search {
    &.full-screen {
      width: unset;
      margin: 0 !important;

      .mat-mdc-dialog-container {
        width: unset;
        margin: 0;

        .mat-mdc-dialog-content {
          height: calc(100vh - 84px);
          overflow: auto;
        }
      }
    }

    &.nowidth {
      margin-left: 0 !important;

      .mat-mdc-dialog-container {
        width: unset;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: $mobile-max-width-trigger) {
  .cdk-global-overlay-wrapper {
    align-items: flex-start !important;
  }
}

.cdk-overlay-pane.pxo-modal {
  @media screen and (max-width: $mobile-max-width-trigger) {
    width: 100vw;
    --mat-dialog-container-small-max-width: 100vw;
    min-width: unset !important;

    &.modal-calendar {
      height: 100vh;

      @supports (height: 100dvh) {
        height: 100dvh;
      }

      & mat-dialog-content {
        max-height: 86vh;
      }
    }
  }

  &.modal-calendar {
    @media screen and (max-width: $tablet-max-width-trigger) and (orientation: landscape) {
      min-height: 100% !important;
    }
  }

  .mat-mdc-dialog-container {
    padding: 0;
    display: flex;
    flex-direction: row;
    background: var(--modal-bg);

    @media screen and (max-width: $mobile-max-width-trigger) {
      border-radius: 0;
    }

    & > * {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .calendar-title {
      .modal-close-cross-btn {
        padding: 0;
        margin-right: -4px;

        @media screen and (max-width: $mobile-max-width-trigger) {
          margin-right: 2px;
        }
      }
    }

    .mat-mdc-dialog-title {
      margin: 0;
      padding: $small-indent $big-indent;
      flex-grow: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include apply-modal-header-font();
      color: var(--font-dark-color);
      border-bottom: $dark-border;

      @media screen and (max-width: $mobile-max-width-trigger) {
        padding: $small-indent-mobile $big-indent-mobile;
      }

      .modal-close-cross-btn {
        padding: 0;
        margin-right: -4px;
        @include apply-single-bacground-image(var(--close-button-cross-icon-url), $modal-close-icon-size);

        @media screen and (max-width: $mobile-max-width-trigger) {
          margin-right: -3px;
        }
      }
    }

    .mat-mdc-dialog-content {
      margin: 0;
      flex-grow: 1;
      padding: $small-indent $big-indent $small-indent * 0.5;

      @include apply-modal-body-font();
      color: var(--light-color);

      @media screen and (max-width: $mobile-max-width-trigger) {
        padding: $small-indent $big-indent-mobile $small-indent * 0.5;
      }
    }

    .mat-mdc-dialog-actions {
      padding: $big-indent;
      margin: 0;
      min-height: unset;
      justify-content: center;
      border-top: 0;

      @media screen and (max-width: $mobile-max-width-trigger) {
        padding: $big-indent-mobile;
      }

      .mat-mdc-button-base {
        height: $modal-action-button-height;
        padding: 0 $small-indent;
        min-width: $modal-action-min-width;

        &:not(:first-child) {
          margin-left: $big-indent;

          @media screen and (max-width: $mobile-max-width-trigger) {
            margin-left: $big-indent-mobile;
          }
        }

        @include apply-modal-action-button-font();

        &.mat-primary {
          background-color: var(--btn-primary-color);
          color: var(--all-theme-white);
        }
      }
    }
  }

  &.modal-calendar .mat-mdc-dialog-container {
    overflow: hidden;
  }
}

.cdk-overlay-pane.my-settings {
  width: 100% !important;
  border-radius: 0;
  max-width: 100% !important;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
    min-height: unset !important;
  }

  .my-setting-radio-button {
    .mat-radio-label {
      white-space: normal;
      align-items: flex-start;
    }
  }

  .mat-mdc-dialog-title {
    padding: 16px 20px 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    color: var(--font-dark-color);

    @media (max-width: $mobile-min-575px-width-trigger) {
      font-size: 16px;
      padding: 10px 15px 10px 18px;
    }
  }

  .mat-mdc-dialog-container {
    width: 628px;
    height: 550px;
    padding: 0;
    margin: 10px auto;
    box-shadow: -2px 2px 10px 3px rgb(0 0 0 / 20%) !important;
    overflow: auto;

    @media (max-width: $mobile-max-width-trigger) {
      width: 100vw;
      height: 100dvh;
      margin: 0;
    }

    .mat-mdc-dialog-actions {
      padding: 0 0 24px !important;
    }
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: 1px solid var(--dark-border-color);
  }

  .mat-mdc-dialog-content {
    @media screen and (max-width: $tablet-min-width-trigger) {
      max-height: 100%;
    }

    &.account-tab-content {
      padding: 24px;
      max-height: 70vh;
    }
  }

  .mat-mdc-tab-body-wrapper {
    overflow: hidden;
    height: 100%;
  }

  .mat-mdc-tab-body {
    margin-top: 50px;
  }

  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    overflow: visible;
    margin-top: 0;
  }

  .my-setting-tabs {
    &.mat-mdc-tab-group {
      overflow: visible;
    }

    .mat-mdc-tab-body-content {
      padding: 24px 24px 0;
      position: relative;

      .mat-mdc-dialog-content {
        padding: 0;
        max-height: 100%;
        overflow: visible;

        @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
          max-height: calc(100dvh - 110px);
        }
      }
    }

    .mat-mdc-tab-body-active {
      @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
        max-height: calc(100dvh - 130px);
      }
    }

    .mat-mdc-tab {
      --mat-tab-header-label-text-weight: 500;
      --mat-tab-header-label-text-size: 14px;
      --mat-tab-header-label-text-tracking: normal;
      --mat-tab-header-label-text-line-height: 20px;
      padding: 0 12px;
      min-width: initial;
      height: 36px;

      .mdc-tab__text-label {
        --mat-tab-header-inactive-label-text-color: var(--font-dark-color);
        --mat-tab-header-inactive-hover-label-text-color: var(--font-dark-color);
        --mat-tab-header-inactive-focus-label-text-color: var(--font-dark-color);
      }

      &:hover:not(.mdc-tab--active) {
        .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
          opacity: 1;
          border-color: var(--grey-bg);
        }
      }

      .mdc-tab__text-label {
        transition: none;
      }

      .mdc-tab__ripple::before {
        opacity: 0;
      }

      .mat-ripple-element {
        display: none;
      }
    }
  }

  .my-setting__wrapper {
    height: calc(100% - 60px);
  }

  @media screen and (max-width: $tablet-min-width-trigger) {
    width: 100vw !important;
    min-width: unset !important;
    min-height: unset !important;

    .my-setting__wrapper {
      .mat-mdc-tab-body-wrapper {
        overflow: hidden;
        height: 100%;
      }
    }
  }
}

.cdk-overlay-pane.feelings {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.cdk-overlay-pane.heatmap-details {
  min-width: 100vw !important;
  min-height: 100vh;
  overflow: auto;

  .mat-dialog-container {
    border-radius: 0 !important;
    padding: 10px !important;

    app-heatmap-tooltip {
      border: 0;
    }
  }
}

// trading panel order (aka OCO Order)
.cdk-overlay-pane.pxo-order-modal,
.trading-panel-order-wrapper {
  display: block;
  width: 370px;
  min-height: 280px;

  @media screen and (max-width: $mobile-min-375px-width-trigger) {
    width: 360px;
  }

  &.pinned-panel {
    height: 100%;
    position: relative;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-right: 4px solid var(--dark-border-color);
    }

    .oco-order-modal-heading {
      cursor: default;
    }
  }

  .mat-mdc-dialog-container {
    max-width: 370px;
    padding: 0;

    @media screen and (max-width: $mobile-min-375px-width-trigger) {
      max-width: 360px;
    }
  }

  .mat-mdc-dialog-title {
    margin: 0;
    padding: 0 15px;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 20px 15px 15px;
    max-height: calc(100% - 41px);
    overflow-y: auto;
  }
}

.oco-order-modal-heading {
  min-height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid var(--divider-color);
  cursor: grab;

  &:active,
  &:focus-visible {
    cursor: grabbing;
  }

  .modal-title {
    @include apply-oco-order-modal-title-font();
    color: var(--oco-order-modal-title-color);
  }

  .modal-heading-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .mat-mdc-button-base.modal-heading-btn {
    --mdc-icon-button-state-layer-size: 40px;
    --mdc-icon-button-icon-size: 24px;
    --mdc-icon-button-icon-color: var(--font-dark-color);
    --global-mat-btn-border-radius: 50%;
    padding: 4px;

    .mat-icon {
      @include apply-icon-size(24px);

      &.pin-icon {
        --mdc-icon-button-icon-color: var(--modal-bg);
        --pin-icon-active-color: var(--modal-bg);
      }
    }
  }

  .mat-mdc-button-base.modal-heading-btn:hover {
    --mdc-icon-button-icon-color: var(--primary-color);
    color: var(--primary-color);
  }

  .mat-mdc-button-base.modal-heading-btn:active,
  .mat-mdc-button-base.modal-heading-btn {
    .mat-icon.pinned {
      --mdc-icon-button-icon-color: var(--primary-color);
      --pin-icon-active-color: var(--primary-color);
      color: var(--primary-color);
    }
  }
}

.oco-order-content {
  margin: 0;
  padding: 20px 15px 15px;

  .mat-mdc-tab-header-pagination {
    display: none;
  }
}

.trading-panel-order-panel-wrapper {
  .trading-panel-order-wrapper.pinned-panel {
    margin-top: -53px;
    margin-right: -20px;
    border-left: 1px solid var(--divider-color);
    height: calc(100dvh - 98px);

    @media screen and (max-width: $tablet-992px-width-trigger) {
      height: auto;
    }

    @include ipad() {
      height: calc(100dvh - 210px);
    }
  }
}

.links-organiser.modals {
  .mat-mdc-dialog-container {
    padding: 16px !important;
    @media screen and (max-width: $mobile-max-width-trigger) {
      padding: 0 !important;
      height: 100dvh;
    }
  }
}

.metric-details-modal.modals {
  .mat-mdc-dialog-content {
    --modal-header-height: 57px;
    --modal-footer-height: 61px;

    max-height: calc(100dvh - var(--modal-header-height) - var(--modal-footer-height));
    height: calc(100% - var(--modal-header-height) - var(--modal-footer-height));

    @media screen and (max-width: $mobile-max-width-trigger) {
      --modal-header-height: 45px;

      max-height: calc(100dvh - var(--modal-header-height) - var(--modal-footer-height));
      height: calc(100% - var(--modal-header-height) - var(--modal-footer-height));
    }
  }
}

.trades-group-modal.modals {
  .mat-mdc-dialog-content {
    max-height: 80dvh;
    overflow: hidden;

    @media screen and (max-width: $mobile-max-width-trigger) {
      max-height: calc(100dvh - 49px - 84px);
    }

    @media screen and (max-width: $mobile-min-575px-width-trigger) {
      max-height: calc(100dvh - 49px - 72px);
    }

    @media screen and (max-width: $tablet-992px-width-trigger) and (orientation: landscape) {
      max-height: 80dvh;
      overflow-y: auto;
    }
  }
}

.scanner-setting.modals {
  .mat-mdc-dialog-container {
    border-radius: 4px !important;
    padding: 0 !important;
    width: 628px;
    margin: 50px auto;
  }

  .mat-mdc-dialog-actions {
    justify-content: space-between;
  }
}

.mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
.mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 20px;
}

.import-export-symbol .mat-form-field-infix {
  border: 0 !important;
}

.import-export-symbol {
  width: 372px !important;
  border-radius: 0;
}

.import-export-symbol .mat-dialog-container {
  border-radius: 4px !important;
  padding: 0 !important;
}

.import-export-symbol .mat-dialog-content {
  margin: 0 !important;
  padding: 24px 24px 0px 24px !important;
}

.import-export-symbol .input {
  width: 100%;
  padding-left: 8px;
  color: var(--input-color);
}

.import-export-symbol .full-input label {
  color: var(--font-dark-color-2);
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}

.full-input label,
.mat-error {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  display: block;
}

.mat-error {
  margin-top: 8px;
}

.import-export-symbol .mat-typography img {
  margin-bottom: 24px;
}

.import-export-symbol .mat-typography h4 {
  margin-bottom: 16px;
  color: var(--black-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.import-export-symbol .mat-typography p {
  margin-bottom: 0;
  color: var(--font-dark-color);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
}

.import-export-symbol .input {
  margin-bottom: 0;
  border: 1px solid var(--light-border-color);
  background: transparent;
  color: var(--input-color);
}

.import-export-symbol .full-input {
  margin-bottom: 16px;
}

.import-export-symbol .full-input:last-of-type {
  margin-bottom: 0;
}

.import-export-symbol .choose-file-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  background: var(--light-hover-color);
  padding: 4px;
  border-radius: 4px;
}

.import-export-symbol .choose-file-div input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 28px;
  width: 96px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.import-export-symbol .choose-file-btn {
  height: 28px;
  width: 96px;
  background-color: var(--dark-bg-3) !important;
  color: var(--font-dark-color) !important;
  border: 0 !important;
  font-family:
    Trebuchet MS,
    roboto,
    ubuntu,
    sans-serif !important;
}

.import-export-symbol .choose-file-div p {
  margin-left: 8px;
  color: var(--font-dark-color-4);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.import-export-symbol .import-label {
  margin-bottom: 4px;
}

.import-export-symbol .sample-file {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.import-export-symbol .sample-file label,
.import-export-symbol .sample-file a {
  margin-bottom: 8px;
  color: var(--light-color);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 15px;
}

.import-export-symbol .sample-file a {
  color: var(--primary-color);
  text-decoration: underline;
}

.import-export-symbol .sample-file a:hover {
  color: var(--primary-color);
}

.import-export-symbol .mb8 {
  margin-bottom: 8px !important;
}

.import-export-symbol .input {
  font-size: 13px;
  color: var(--input-color);
}

.import-error .mat-mdc-dialog-content span {
  display: block;
  margin-bottom: 12px;
  color: var(--font-dark-color);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
}

.import-error .mat-mdc-dialog-content span:last-of-type {
  margin-bottom: 8px;
}

.oco-order-tabs-wrapper {
  .from-group-row {
    .mat-mdc-form-field .mat-mdc-form-field-infix {
      padding-block: 3px;
      min-height: 32px;
      width: 100%;
    }
  }
}

.metric-details-modal {
  max-width: 1100px;
  height: fit-content;
  max-height: 100dvh;
}
