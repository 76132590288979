@import '../mixins/mobile-devices';
@import '../values';

.mat-mdc-form-field {
  --mat-form-field-container-text-size: 14px;

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: 5px;
    --mdc-outlined-text-field-input-text-color: var(--main-font-color);
    --mdc-outlined-text-field-outline-color: var(--dark-border-color);
    --mdc-outlined-text-field-hover-outline-color: var(--mat-form-field-outline-border-color);
  }

  .mat-mdc-text-field-wrapper {
    padding-inline: 12px;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding-inline: 8px;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 4px;
  }

  &.mat-mdc-form-field-type-mat-select {
    &.date-select-field {
      .mdc-text-field.mdc-text-field--outlined {
        --mdc-outlined-text-field-outline-color: transparent;
        --mdc-outlined-text-field-hover-outline-color: transparent;
        padding-inline: 10px;
        background-color: var(--light-bg-5);
        border-radius: 3px;
      }

      .mat-mdc-form-field-flex {
        padding-inline: 0;

        .mat-mdc-form-field-infix {
          padding-block: 0;
          width: 160px;
          min-height: 26px;
          border: none !important;

          .date-mdc-select {
            .mat-mdc-select-value-text {
              display: inline;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.scanner-filter-field {
    --mat-form-field-container-text-size: 12px;
    --mat-form-field-container-text-line-height: 14px;

    .mat-mdc-text-field-wrapper {
      padding-inline: 7px;

      .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 22px;
      }
    }

    .mat-mdc-floating-label {
      --mat-form-field-outlined-label-text-populated-size: 14px;
      --mdc-outlined-text-field-label-text-color: var(--input-color);
    }
  }

  input.mat-mdc-input-element::placeholder {
    color: var(--input-placeholder-font-color) !important;
  }

  &.bcs-form-field {
    .mat-mdc-form-field-infix {
      --mat-form-field-container-vertical-padding: 0;
      --mat-form-field-container-height: auto;
      height: 28px;
      background-color: transparent;

      .bcs-select {
        .mat-mdc-select-trigger {
          padding: 2px 0;
          display: flex;
          align-items: center;
          gap: 4px;

          .mat-mdc-select-arrow-wrapper {
            display: block;
            height: auto;
          }
        }

        .mat-mdc-form-field-infix {
          --mat-form-field-container-vertical-padding: 0;
          --mat-form-field-container-height: 28px;
        }
      }
    }

    .mdc-notched-outline__leading {
      width: 4px !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
      --mdc-outlined-text-field-label-text-size: 14px;
      --mat-form-field-outlined-label-text-populated-size: 14px;
      --mdc-outlined-text-field-label-text-color: var(--bsc-input-label-color);
      --mdc-outlined-text-field-hover-label-text-color: var(--bsc-input-label-color);
      padding-inline: 0 4px;
      max-width: fit-content;
      background-color: var(--info-bg-color);
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: transparent;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.bull-call-spread {
  .mat-mdc-form-field .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
    padding-top: 2px;
    padding-inline: 0;
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }
}

// Attention pls! don't use class from-group
.from-group {
  @include iphone-X-XS-XR-11-and-portrait {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-X-XS-XR-11-and-landscape {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-6-6s-7-8-portrait {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-6-6s-7-8-landscape {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-inline: 12px;
  }

  .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label {
    opacity: 0;
    margin: 0 !important;
    transform: none !important;
    transition: 0s !important;
  }

  .mat-mdc-form-field-text-prefix,
  .mat-mdc-form-field-suffix {
    text-align: center;
    color: var(--font-dark-color-4);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    top: -0.125em;
  }

  &.invalid {
    .mat-mdc-form-field-suffix,
    .mat-mdc-form-field-text-prefix {
      > span {
        color: var(--danger-color);
      }

      > span > span {
        border-color: var(--danger-color);
      }
    }

    .mat-mdc-form-field-flex,
    .mat-focused .mat-mdc-form-field-flex {
      border-color: var(--danger-color);
    }
  }

  input.mat-mdc-input-element {
    margin-top: 0;
    height: 1.3755em;
  }
}

// from-group end

.help {
  width: 12px;
  fill: #e0e3eb;
  margin: 0 10px;

  path:last-child {
    fill: #787b86;
  }
}

.new-input-style {
  .mat-mdc-form-field {
    margin-top: 0;
    width: 100%;
    height: 100%;
  }

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    --mdc-outlined-text-field-outline-color: transparent;
    padding: 0;
    height: 100%;
  }

  .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label {
    opacity: 0;
    margin: 0 !important;
    transform: none !important;
    transition: 0s !important;
  }

  .mat-mdc-form-field-flex {
    background: transparent;
    border: 1px solid var(--light-border-color);
    border-radius: 4px;
    padding: 0;
    height: 100%;

    .black-theme & {
      border-color: #2f323c;
    }
  }

  .mat-mdc-form-field-infix {
    padding-block: 0 !important;
    height: 100%;
    min-height: auto;
    border-top: none;
    position: relative;
  }

  .mat-mdc-form-field-text-prefix,
  .mat-mdc-form-field-suffix {
    text-align: center;
    color: var(--font-dark-color-4);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;

    > span > span {
      padding: 0 4px;
      min-width: 27px;
      display: inline-block;
    }
  }

  .mat-mdc-form-field-text-prefix {
    margin-left: -12px;
    margin-right: 12px;

    > span > span {
      border-right: 1px solid var(--light-border-color-4);
    }
  }

  .mat-mdc-form-field-suffix {
    margin-right: -12px;
    margin-left: 12px;

    > span > span {
      border-left: 1px solid var(--light-border-color-4);
    }
  }

  .mat-mdc-form-field-label-wrapper {
    position: absolute;
  }

  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
    position: relative;
    padding: 7px 10px;
    top: 0;
  }

  input.mat-mdc-input-element {
    margin-top: 0;
    height: 100% !important;
    padding: 0 10px;
    border-radius: 3px;

    &::placeholder {
      text-transform: capitalize;
      color: var(--table-cell-placeholder) !important;
    }
  }
}

.search-input {
  .mat-mdc-form-field-suffix {
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
  }
}

.form-group {
  height: 32px;

  &.w-full {
    width: 100%;

    .mat-mdc-form-field-flex {
      width: 100%;
      min-width: initial;
      max-width: 100%;
    }
  }

  @include iphone-X-XS-XR-11-and-portrait {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-X-XS-XR-11-and-landscape {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-6-6s-7-8-portrait {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  @include iphone-6-6s-7-8-landscape {
    .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
      top: 1.2em;
    }
  }

  .mat-mdc-form-field {
    height: 100%;
    width: 100%;
  }

  .mat-mdc-form-field-underline {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-inline: 12px;
    height: 100%;
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin: 0;
    top: 0;
    position: relative;
    height: 0;

    .mat-mdc-form-field-hint {
      font-weight: 400;
      font-size: 11px;
      line-height: 130%;
      color: var(--asset-correlation-sub-header-color);
      display: block;
      width: 100%;
    }

    .mat-mdc-form-field-hint-spacer {
      display: none;
    }

    .mat-right {
      text-align: right;
    }
  }

  .mat-mdc-form-field-flex {
    padding: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 4px;
    height: 100%;
  }

  .mat-focused .mat-mdc-form-field-flex {
    border-color: var(--primary-color);
  }

  .mat-mdc-form-field-infix {
    padding: 0 0 0.3844em 0;
    border-top: none;
  }

  .mat-mdc-form-field-text-prefix,
  .mat-mdc-form-field-suffix {
    text-align: center;
    color: var(--font-dark-color-4);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    top: -0.125em;

    > span > span {
      padding: 0 4px;
      min-width: 27px;
      display: inline-block;
    }
  }

  .mat-mdc-form-field-text-prefix {
    margin-left: -12px;
    margin-right: 12px;

    > span > span {
      border-right: 1px solid var(--light-border-color-4);
    }
  }

  .mat-mdc-form-field-suffix {
    margin-right: -12px;
    margin-left: 12px;

    > span > span {
      border-left: 1px solid var(--light-border-color-4);
    }
  }

  input.mat-mdc-input-element {
    margin-top: 0;
    height: 1.3755em;
  }

  .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mat-mdc-form-field-text-prefix {
    margin-right: 0;
    top: 0;
    display: flex;
    align-items: center;
  }

  .mat-mdc-form-field-suffix {
    margin-right: 5px;
    margin-left: 0px;
    top: 0;
    display: flex;
    align-items: center;
  }

  input.mat-mdc-input-element {
    --mdc-filled-text-field-input-text-color: var(--main-font-color);
    --mdc-filled-text-field-caret-color: var(--primary-color);
    color: var(--main-font-color);
  }

  .mat-mdc-form-field-label-wrapper {
    padding: 0 0 0 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    top: 0;

    .mat-mdc-form-field-label {
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      color: var(--input-placeholder-font-color);

      span {
        padding-left: 5px;
        line-height: 100%;
      }
    }
  }

  &.align-right {
    input.mat-mdc-input-element {
      text-align: right;
    }
  }

  &.invalid .mat-mdc-form-field-flex {
    .mat-mdc-form-field-label {
      color: var(--danger-color);
    }

    .mat-mdc-form-field-text-prefix {
      color: var(--danger-color);

      span {
        border-color: var(--danger-color);
      }
    }
  }

  .search-input {
    .mat-mdc-form-field-suffix {
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px 0 0;
    }
  }

  &.form-label {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    .top-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--description-color);
      margin-bottom: 2px;

      &.label-icon-container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .mat-mdc-form-field-infix:not(:has(.bcs-input, .bcs-select)) {
      background-color: var(--trading-log-table-bg);
      border-radius: 4px;
    }
  }

  .mat-mdc-form-field-flex {
    border-radius: 4px !important;
  }

  &.input-hint {
    .mat-mdc-form-field-subscript-wrapper {
      top: unset !important;
      left: 2px;
      position: absolute;
    }
  }

  input.mat-mdc-input-element.wheel-input,
  input.mat-mdc-input-element.bcs-input {
    border: 1px solid var(--light-border-color);
    background: var(--info-bg-color);
    color: var(--input-color);
    border-radius: 4px;
    padding: 4px 7px;
    height: 32px;
    margin: 0;
    font-size: 12px;

    &::-webkit-input-placeholder {
      color: var(--light-color-3);
    }

    &::-moz-placeholder {
      color: var(--light-color-3);
    }

    &:-ms-input-placeholder {
      color: var(--light-color-3);
    }

    &::-ms-input-placeholder {
      color: var(--light-color-3);
    }

    &::placeholder {
      color: var(--light-color-3);
    }
  }

  input.mat-mdc-input-element.wheel-input {
    height: 28px;
  }

  .mat-mdc-form-field.w-100,
  .mat-mdc-form-field.full-width {
    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-infix {
      width: 100%;
      min-width: initial;
      max-width: 100%;
    }
  }
}

.mat-mdc-form-field.w-100,
.mat-mdc-form-field.full-width {
  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-infix {
    width: 100%;
    min-width: initial;
    max-width: 100%;
  }
}

.mdc-line-ripple {
  display: none;
}

.bcs-filter .filters .input-item .mat-mdc-form-field {
  .mat-mdc-form-field-label:not(.mat-mdc-form-field-empty) {
    padding-inline: 4px;
    display: inline-block;
    width: min-content;
    font-size: 14px;
    height: min-content;
    color: var(--bsc-input-label-color);
    background-color: var(--info-bg-color);
    position: absolute;
    top: 12px !important;
    left: 4px;
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  }

  &.mat-focused .mat-mdc-form-field-label:not(.mat-mdc-form-field-empty) {
    color: var(--primary-color);
  }
}

.oco-order-tabs-wrapper {
  .mat-mdc-text-field-wrapper {
    padding-inline: 12px;
  }

  .mat-form-field-disabled {
    .mat-mdc-text-field-wrapper {
      background-color: var(--oco-order-modal-price-ticker-btn-bg-color);
      border-radius: 4px;

      .mat-mdc-form-field-flex {
        border-color: transparent;
      }
    }
  }
}

.stocks-dropdown-menu {
  .form-group .mat-mdc-text-field-wrapper {
    background-color: var(--stocks-dropdown-input-bg-color);
    border-color: var(--stocks-dropdown-input-border-color);
  }
}

.tradier-import-settings .symbol-settings,
.trades-group-settings {
  .form-group input.mat-mdc-input-element,
  .form-group .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label span {
    padding-left: 5px;
  }
}

/*CUSTOM INPUT OUTLINE COLOR FOR LOGIN PAGE FORM*/
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.2);
}

/* Change autocomplete styles in WebKit Browsers for LOGIN PAGE */
.login--page .login-form {
  mat-form-field {
    .mat-mdc-form-field-error-wrapper {
      padding-top: 4px;
      padding-inline: 0;
    }

    &:has(input:-webkit-autofill) .mdc-floating-label {
      --mat-mdc-form-field-label-transform: translateY(-30.75px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      --mat-form-field-outlined-label-text-populated-size: 14px;
      --mdc-outlined-text-field-label-text-size: 14px;
      padding-inline: 3px;
      background-color: var(--white-color);
      transform: var(--mat-mdc-form-field-label-transform);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-internal-autofill-selected,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input:-webkit-autofill::first-line {
    background-color: transparent !important;
    border: none !important;
    border-radius: 5px;
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0 1000px yellow inset !important;
    box-shadow: 0 0 0 rgb(255 255 255) !important;
    transition:
      background-color 0s 600000s,
      color 0s 600000s;
  }
}

input.mat-mdc-input-element.mdc-text-field__input {
  --mdc-filled-text-field-input-text-color: var(--main-font-color);
  --mdc-filled-text-field-caret-color: var(--primary-color);
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  --mdc-filled-text-field-error-caret-color: var(--danger-color);
  --mdc-filled-text-field-error-text-color: var(--danger-color);
}

.tradier-select,
.account-select {
  .mat-mdc-form-field .mat-mdc-form-field-infix {
    padding-block: 3px;
    width: auto;
    min-height: 32px;
  }
}

.yt-input-container {
  .mdc-text-field {
    padding: 0;
    border: 1px solid var(--secondary-btn-border);
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      border: 1px solid var(--economic-calendar-card-data-bg-color);
    }
    &::placeholder {
      color: var(--content-color-3);
    }
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: var(--white-bg);
  }
  .mdc-text-field--focused {
    border-radius: 5px;
    border: 1px solid var(--economic-calendar-card-data-bg-color);
  }
}

.wheel-filter.wtf-scanner-filter,
.wheel-filter.dividends-scanner-filter,
.wheel-filter.short-selling-scanner-filter {
  .form-group .mat-mdc-text-field-wrapper {
    padding-inline: 0 !important;
  }
  .form-group .mat-mdc-form-field {
    height: 22px;
  }
}

.trading-panel-order {
  .mat-mdc-form-field .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
    padding-inline: 0;
  }
}

.trade-zero-login-form {
  .mdc-text-field--outlined .mdc-text-field__input {
    height: 40px;
  }

  .trade-zero-form-group {
    height: 40px;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--trade-zero-placeholder-text-color);
    font-family: $trebuchet-font;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label {
    color: var(--trade-zero-placeholder-text-color);
  }
}
